import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Calendar } from 'lucide-react';
import CourseInformationCard from '../search/SeelctedCourseInformation/CourseInformationCard';
import { GolfCourse } from '../../utils/api/types';

interface FeatureCardProps {
  icon: typeof Calendar;
  title: string;
  description: string;
}

export function FeatureCard({ icon: CardIcon, title, description }: FeatureCardProps) {
  return (
    <div className="bg-gray-800 p-6 rounded-lg flex items-start space-x-4">
      <CardIcon className="w-8 h-8 text-yellow-500 flex-shrink-0" />
      <div>
        <h3 className="text-xl font-semibold mb-2 text-white">{title}</h3>
        <p className="text-gray-400">{description}</p>
      </div>
    </div>
  );
}

export function FlippableCourseCard() {
  const [isFlipped, setIsFlipped] = useState(false);

  const bethpageBlack: GolfCourse = {
    course_id: 1,
    maxBookingDays: 7,
    courseName: 'Bethpage Black State Park Golf Course',
    courseLocation: 'Farmingdale, NY',
    par: 71,
    yardage: 7465,
    rating: 77,
    slope: 155,
    image:
      'https://www.golfdigest.com/content/dam/images/golfdigest/fullset/2019/05/03/5ccc6e2aeecacf69bdc395f5_18b.jpg', // Replace with actual image path
  };

  const torreyPines: GolfCourse = {
    course_id: 2,
    maxBookingDays: 7,
    courseName: 'Torrey Pines South Course',
    courseLocation: 'San Diego, California',
    par: 72,
    yardage: 7802,
    rating: 78,
    slope: 148,
    image:
      'https://golfdigest.sports.sndimg.com/content/dam/images/golfdigest/fullset/2019/01/14/5c3bf24cf193692d16a72559_186%20-%20Torrey%20Pines%20G.%20Cse.%20(South)%20-%20Jon%20Cavalier.jpeg.rend.hgtvcom.966.544.suffix/1573163040843.jpeg', // Replace with actual image path
  };

  return (
    <div
      className="relative w-full h-full group flex items-center justify-center"
      onMouseEnter={() => setIsFlipped(true)}
      onMouseLeave={() => setIsFlipped(false)}
    >
      <div
        className={`absolute inset-0 transition-all duration-500 [transform-style:preserve-3d] ${
          isFlipped ? '[transform:rotateX(180deg)]' : ''
        }`}
      >
        <div className="absolute inset-0 [backface-visibility:hidden]">
          <CourseInformationCard
            isSelected={false}
            displayedCourse={bethpageBlack}
            courseSearches={12}
            setSelectedCourse={() => {}}
          />
        </div>
        <div className="absolute inset-0 [backface-visibility:hidden] [transform:rotateX(180deg)]">
          <CourseInformationCard
            isSelected={false}
            displayedCourse={torreyPines}
            courseSearches={8}
            setSelectedCourse={() => {}}
          />
        </div>
      </div>
    </div>
  );
}

interface TechnologyCardProps {
  title: string;
  description: string;
  imageUrl: string;
}
export function TechnologyCard({ title, description, imageUrl }: TechnologyCardProps) {
  const [isFlipped, setIsFlipped] = useState(false);
  return (
    <div
      className="relative w-44 h-64 group"
      onMouseEnter={() => setIsFlipped(true)}
      onMouseLeave={() => setIsFlipped(false)}
    >
      <div
        className={`absolute w-full h-full transition-all duration-500 [transform-style:preserve-3d] ${isFlipped ? '[transform:rotateY(180deg)]' : ''}`}
      >
        {/* Front of the card */}
        <div className="absolute w-full h-full bg-gray-800 rounded-lg p-6 flex flex-col items-center justify-between [backface-visibility:hidden]">
          <img
            src={imageUrl}
            alt={title}
            className="w-36 h-28 object-contain rounded-lg "
          />
          <h3 className="text-xl font-semibold text-white text-center">{title}</h3>
        </div>

        {/* Back of the card */}
        <div className="absolute w-full h-full bg-gray-800 rounded-lg p-6 flex items-center justify-center [backface-visibility:hidden] [transform:rotateY(180deg)]">
          <p className="text-white text-center">{description}</p>
        </div>
      </div>
    </div>
  );
}
